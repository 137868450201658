@font-face {
  font-family: 'Tiempos Headline';
  font-display: swap;
  src: url('/fonts/tiempos-headline-bold.otf');
}

$font-family-sans-serif: Europa, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol' !default;

$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;

$blue: #2b87f9 !default;

$input-focus-box-shadow: none;
$pagination-border-width: none;
