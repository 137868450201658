.flex-1 {
  flex: 1;
}

.shadow-soft {
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.0461673);
}

.text-orange {
  color: #ffb74d;
}
