// Google maps autocomplete Styling
.pac-container {
  font-family: Europa, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  border-radius: 4px;
  font-size: 14px;
  margin-top: 8px;
  z-index: 99999999; //We need this z-index to make the autocomplete options work in modals

  .pac-item {
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    border: none;
    line-height: 16px;

    &:first-child {
      padding-top: 12px;
    }
    &:last-child {
      padding-bottom: 12px;
    }
  }
  .pac-matched {
    font-weight: normal;
    font-size: 14px;
  }
  .pac-icon {
    display: none;
  }
}
.pac-logo:after {
  display: none !important;
  background-image: none !important;
}
