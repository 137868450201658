@tailwind base;
@tailwind components;
@tailwind utilities;

@import './variables.scss';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './utilities.scss';

body {
  background-color: #eef2f6;
}

html,
body,
#__next,
#app {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;
}

.form-control::placeholder {
  color: #d3d8dc;
}

#__next-prerender-indicator {
  display: none;
}
